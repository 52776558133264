import React from "react";
import {
  Typography,
  Box,
  Paper,
  Divider,
  Button,
  IconButton
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import StarIcon from "@mui/icons-material/Star";

const theme = createTheme({
  palette: {
    primary: {
      main: "#B5c0af"
    },
    secondary: {
      main: "#F7F5F3"
    },
    tertiary: "#EBE2D6",
    quaternary: "#D1AE9F",
    quinary: "#8B635C"
  }
});

const Service = ({ title, description }) => (
  <Box my={4}>
    <Paper elevation={3} sx={{ "&:hover": { transform: "scale(1.03)" } }}>
      <Box p={3}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5" gutterBottom>
            {title}
          </Typography>
          <IconButton>
            <StarIcon color="primary" />{" "}
          </IconButton>
        </Box>
        <Divider />
        <Typography variant="body1" mt={2}>
          {description}
        </Typography>
        <Box mt={3}>
          <Button
            href="https://afterglobeautyco.square.site/"
            target="_blank"
            variant="outlined"
            style={{
              color: theme.palette.quinary,
              borderColor: theme.palette.quinary
            }}
          >
            Book This Service
          </Button>
        </Box>
      </Box>
    </Paper>
  </Box>
);

const ServicesPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box m={4}>
        <Typography variant="h4" align="center" gutterBottom>
          Exclusive Services
        </Typography>

        {/* Lash lift&tint */}
        <Service
          title="Lash Lift & Tint:"
          description="Not an extension girlie? No worries! Lash lifts add a natural curl that opens up your eyes by using a safe perming solution to hold up your lashes! With the lash tint, your lashes will be dark in similarity to mascara. This service takes about an hour and your lashes will stay lifted for about 6-8 weeks! No need for curlers or mascara anymore."
        />

        {/* Volume Lash Extensions */}
        <Service
          title="Lash Extensions:"
          description="Lash extensions are extremely convenient and will quite literally change your life. I price my fills and full sets the same for every lash style. No matter what type of lashes you want, I will do everything I can to make you love your lashes and style them to your preference and eye shape. You will leave feeling full, clean, and confident after every appointment. You are welcome to change it at any time at no additional cost."
        />

        {/* Eyebrow Lamination */}
        <Service
          title="Eyebrow Lamination:"
          description="This service sets your natural brow hairs into your desired shape using a safe perming solution. Brows will look fuller, neater, and perfected. It's a quick and comfortable process with results that last for weeks, giving you effortlessly styled brows everyday."
        />

        {/* Eyebrow wax */}
        <Service
          title="Eyebrow wax:"
          description="I will perfectly wax your brows to your desired shape using hard wax."
        />

        {/* GLOCO Brow Works */}
        <Service
          title="GLOCO Brow Works:"
          description="If you want PERFECT brows, book this service for a wax, tint, and lamination."
        />
      </Box>
    </ThemeProvider>
  );
};

export default ServicesPage;
