import React, { useState } from "react";
import { Box, TextField, Button, Typography } from "@mui/material";
import emailjs from "emailjs-com";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: ""
  });
  const [successMessage, setSuccessMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(
        "service_1n0cy1s",
        "template_u2eabe4",
        formData,
        "MJpk6dVWkxqgwxPEE"
      )
      .then(
        (result) => {
          setSuccessMessage("Message sent successfully!");
          setFormData({ name: "", email: "", message: "" });
        },
        (error) => {
          setSuccessMessage("Failed to send message. Please try again.");
        }
      );
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        margin: "auto",
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        gap: 2,
        backgroundColor: "#F7F5F3",
        borderRadius: "10px",
        boxShadow: 3
      }}
    >
      <Typography variant="h4" component="h2" color="#B5C0AF">
        Contact Me
      </Typography>
      <TextField
        label="Name"
        name="name"
        variant="outlined"
        fullWidth
        value={formData.name}
        onChange={handleChange}
        required
      />
      <TextField
        label="Email"
        name="email"
        variant="outlined"
        fullWidth
        value={formData.email}
        onChange={handleChange}
        type="email"
        required
      />
      <TextField
        label="Message"
        name="message"
        variant="outlined"
        fullWidth
        multiline
        rows={4}
        value={formData.message}
        onChange={handleChange}
        required
      />
      <Button
        type="submit"
        variant="contained"
        sx={{
          backgroundColor: "#B5C0AF",
          "&:hover": {
            backgroundColor: "#8B635C"
          }
        }}
      >
        Send
      </Button>
      {successMessage && (
        <Typography variant="body2" color="#B5C0AF" mt={2}>
          {successMessage}
        </Typography>
      )}
    </Box>
  );
};

export default ContactForm;
