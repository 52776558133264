import React from "react";
import "../styles/EducationStyle.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Button from "@mui/material/Button";

const theme = createTheme({
  palette: {
    primary: {
      main: "#B5C0AF"
    },
    secondary: {
      main: "#F7F5F3"
    },
    tertiary: "#EBE2D6",
    quaternary: "#D1AE9F",
    quinary: "#8B635C"
  }
});

const Education = () => {
  return (
    <ThemeProvider theme={theme}>
      <div className="education-page">
        <header className="education-header">
          <h1>BECOME LASH LICENSED</h1>
          <p>
            I am SO excited to finally announce I am starting 1 on 1 lash
            trainings! This is a 4-day, 30-hour hybrid beginner lash training
            program approved by the Arizona State Board of Cosmetology. Upon
            completion, you’ll earn an official and legal lash extension
            technician license and certification.
          </p>
          <p>
            This course is open to anyone in Arizona looking to become a
            certified lash technician.
          </p>
        </header>

        <section className="who-can-sign-up">
          <h2>WHO CAN SIGN UP</h2>
          <p>
            Great news! In the state of Arizona, you no longer need to be a
            licensed esthetician to perform lash extensions.
          </p>
          <p>
            In order to get licensed, you can take this 30-hour lash extension
            course. Anyone can sign up who has:
          </p>
          <ul>
            <li>Proof of at least two years of high school education</li>
            <li>Proof of citizenship or legal residency</li>
          </ul>
        </section>

        <section className="what-i-teach">
          <h2>WHAT I WILL TEACH YOU</h2>
          <p>The 30-Hour Lash Technician Licensing Content includes:</p>
          <ul>
            <li>
              Eye structure, function, and disorders of the eye and orbital
              areas
            </li>
            <li>Eyelash growth cycles</li>
            <li>Contraindications and allergic reactions</li>
            <li>Infection control</li>
            <li>Eye shapes and eyelash evaluation</li>
            <li>Product ingredients and retention</li>
            <li>Health and Safety</li>
            <li>Board statutes and rules</li>
            <li>Client consultation and custom lash design</li>
            <li>Cleansing the eye area</li>
            <li>Applying and removing eyelash extensions</li>
            <li>Social media, pricing, and business basics</li>
            <li>Hours of hands-on practice and instruction</li>
            <li>Scripts and templates for client communication</li>
          </ul>
          <p>
            By completing this course, you’ll feel fully prepared and confident
            in your skills as a lash artist. This course prepares you for the
            real lash world and provides more than what is required by the
            state.
          </p>
        </section>

        <section className="extras">
          <h2>WHAT YOU GET</h2>
          <p>When you sign up, you’ll receive:</p>
          <ul>
            <li>A full lashing kit</li>
            <li>The "Perfect Response" e-book</li>
            <li>The "Steps to Starting Your Legal Business" e-book</li>
          </ul>
          <p>
            The lash industry can be overwhelming, but I’m here to guide you and
            be your forever lash mentor so you can fall in love with this
            career.
          </p>
          <Button
            variant="contained"
            color="primary"
            href="https://docs.google.com/forms/d/e/1FAIpQLSfbGVqEmojSveMbCgGQcOyDLTG1Q0Wv5lYt2cbajD5S2V_XnA/viewform?vc=0&c=0&w=1&flr=0"
            target="_blank"
            style={{ marginTop: "20px" }}
          >
            Sign Up Now
          </Button>
        </section>
      </div>
    </ThemeProvider>
  );
};

export default Education;
