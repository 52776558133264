import React from "react";

const Contact = () => {
  const isBigScreen = window.innerWidth > 768;

  return (
    <div>
      {isBigScreen ? (
        <div
          style={{
            padding: "10px",
            display: "flex",
            backgroundColor: "#F7F5F3",
            height: "100vh"
          }}
        >
          {/* Image Gallery */}
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
              gap: "20px"
            }}
          >
            <img
              src={"/court1.jpeg"}
              alt="1"
              style={{
                width: "100%",
                borderRadius: "10px",
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)"
              }}
            />
            <img
              src={"/court2.jpeg"}
              alt="2"
              style={{
                width: "100%",
                borderRadius: "10px",
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)"
              }}
            />
            <img
              src={"/court3.jpeg"}
              alt="3"
              style={{
                width: "100%",
                borderRadius: "10px",
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)"
              }}
            />
          </div>
          <div style={{ textAlign: "center", padding: "20px" }}>
            <h1>Contact Me!</h1>
            <h3>Courtney Weisenberger</h3>
            <a href="mailto:courtmweis@gmail.com">courtmweis@gmail.com</a>
          </div>
        </div>
      ) : (
        // Contact Information for small screens
        <div>
          <div style={{ textAlign: "center", padding: "10px" }}>
            <h1>Contact Me!</h1>
            <h3>Courtney Weisenberger</h3>
            <a href="mailto:courtmweis@gmail.com">courtmweis@gmail.com</a>
          </div>
          <div>
            <img
              src={"/court2.jpeg"}
              alt="2"
              style={{
                width: "100%",
                borderRadius: "10px",
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)"
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Contact;
