import React, { useState, useEffect } from "react";
import HeroComponent from "../components/Hero";
import { Button, Typography, Box } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Footer from "../components/Footer";
import ContactForm from "../components/ContactForm";

const theme = createTheme({
  palette: {
    primary: {
      main: "#B5c0af"
    },
    secondary: {
      main: "#F7F5F3"
    },
    tertiary: "#EBE2D6",
    quaternary: "#D1AE9F",
    quinary: "#8B635C"
  },
  typography: {
    fontFamily: "'Almond', sans-serif" // Set Almond as the primary font
  }
});

const interpolateColor = (color1, color2, factor) => {
  const r1 = parseInt(color1.substring(1, 3), 16);
  const g1 = parseInt(color1.substring(3, 5), 16);
  const b1 = parseInt(color1.substring(5, 7), 16);

  const r2 = parseInt(color2.substring(1, 3), 16);
  const g2 = parseInt(color2.substring(3, 5), 16);
  const b2 = parseInt(color2.substring(5, 7), 16);

  const r = Math.round(r1 + factor * (r2 - r1));
  const g = Math.round(g1 + factor * (g2 - g1));
  const b = Math.round(b1 + factor * (b2 - b1));

  return `#${("0" + r.toString(16)).slice(-2)}${("0" + g.toString(16)).slice(
    -2
  )}${("0" + b.toString(16)).slice(-2)}`;
};

const Home = () => {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Calculate the percentage of page scrolled (0 <= scrollPercent <= 1)
  const totalScrollableHeight =
    document.documentElement.scrollHeight - window.innerHeight;
  const scrollPercent = Math.min(scrollY / totalScrollableHeight, 1);

  const scaleValue = 1 - 0.3 * scrollPercent;
  const backgroundColor = interpolateColor("#F7F5F3", "#FFFFFF", scrollPercent);

  const heroStyle = {
    transform: `scale(${scaleValue})`,
    transition: "transform 0.3s ease-out, backgroundColor 0.3s ease-out",
    backgroundColor: backgroundColor
  };

  return (
    <ThemeProvider theme={theme}>
      <HeroComponent theme={theme} style={heroStyle} />
      <Box style={{ margin: "20px" }} textAlign="center" my={2}>
        <Button
          fullWidth
          size="large"
          color="primary"
          variant="contained"
          target="_blank"
          href="https://afterglobeautyco.square.site/"
        >
          Book Now
        </Button>

        {/* Lash Licensed button */}
        <Button
          href="https://docs.google.com/forms/d/e/1FAIpQLSfbGVqEmojSveMbCgGQcOyDLTG1Q0Wv5lYt2cbajD5S2V_XnA/viewform?vc=0&c=0&w=1&flr=0"
          fullWidth
          size="large"
          variant="outlined"
          target="_blank"
          style={{
            marginTop: "20px",
            color: theme.palette.quinary,
            borderColor: theme.palette.quinary
          }}
        >
          Become Lash Licensed
        </Button>
      </Box>
      <Box textAlign="center">
        <img
          src={"/couthomepic.JPG"}
          alt="Beauty"
          style={{
            maxWidth: "75%",
            maxHeight: "500px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)"
          }}
        />
      </Box>
      <Box mx={4} my={2} marginBottom={30} marginTop={5}>
        <Typography variant="h4" paragraph>
          Welcome to Afterglo Beautyco!
        </Typography>
        <Typography paragraph>
          I'm so glad you’re here! With over six years of experience in the lash
          industry, my passion is helping you look and feel your best. Whether
          you're a potential client or an aspiring lash artist, I'm here to
          support you every step of the way. I’m currently accepting new lash
          and brow clients, and starting in 2025, I'll be welcoming students to
          my beginner lash extension licensing course.
        </Typography>
        <Typography paragraph>
          Please don't hesitate to reach out to me with any inquiries or
          concerns – I'm here to help you every step of the way. Meet me in the
          afterglo!!!
        </Typography>
        <Box my={3}></Box>

        {/* add marign */}
        <Box my={3}></Box>
        {/*add form that takes name email and message and send email*/}
        <ContactForm />
      </Box>
      <Footer />
    </ThemeProvider>
  );
};

export default Home;
