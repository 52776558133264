import React from "react";
import "../styles/Hero.css";

const Hero = (props) => {
  const TextWithAnimation = ({ className, text }) => (
    <span className={className}>
      {text.split("").map((letter, idx) => (
        <span key={idx} style={{ animationDelay: `${0.05 * idx}s` }}>
          {letter === " " ? "\u00A0" : letter}
        </span>
      ))}
    </span>
  );

  return (
    <div style={{ ...props.style, padding: "15px" }}>
      <div
        style={{
          textAlign: "center",
          padding: "5px"
        }}
      >
        <img
          src={"/afterglologo.png"}
          alt="Logo"
          style={{ maxWidth: "100%", height: "auto" }}
        />
        <TextWithAnimation
          className="fadeInSequential"
          text="Meet me in the afterglo"
        />
      </div>
    </div>
  );
};

export default Hero;
