import React, { useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import "../styles/NavbarStyles.css";
import { NavLink, useNavigate } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import { Typography } from "@mui/material";

const Navbar = () => {
  const [show, setShow] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setShow(true);
  }, []);

  return (
    <nav style={{ backgroundColor: "#B5C0AF", padding: "10px" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          className="icon-container"
          onClick={() => setMenuOpen(!isMenuOpen)}
        >
          {isMenuOpen ? <FaTimes size={30} /> : <FaBars size={30} />}
        </div>
        <Typography
          style={{ marginLeft: "20px" }}
          color={"#F7F5F3"}
          onClick={() => navigate("/")}
        >
          AftergloBeautyco
        </Typography>
      </div>

      <ul className={isMenuOpen ? "menu-open" : ""}>
        {[
          {
            name: "Welcome",
            path: "/"
          },
          {
            name: "Portfolio",
            path: "https://www.instagram.com/afterglobeautyco/"
          }, // Adjust the path
          { name: "Services", path: "/services" },
          { name: "Lash Education", path: "/education" },
          { name: "Menu", path: "/menu" },
          { name: "Contact", path: "/contact" }
        ].map((item) => (
          <CSSTransition
            in={show}
            timeout={500}
            classNames="menu-item"
            key={item.name}
            appear
          >
            <li>
              {item.name === "Portfolio" ? (
                <a
                  href={item.path}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="no-outline"
                  onClick={() => setMenuOpen(false)}
                >
                  {item.name}
                </a>
              ) : (
                <NavLink
                  to={item.path}
                  activeClassName="active"
                  className="no-outline"
                  onClick={() => setMenuOpen(false)}
                >
                  {item.name}
                </NavLink>
              )}
            </li>
          </CSSTransition>
        ))}
      </ul>
    </nav>
  );
};

export default Navbar;
