import React from "react";

const Footer = () => {
  return (
    <footer style={footerStyles}>
      <p>&copy; 2023 Afterglo Beautyco. All rights reserved.</p>
    </footer>
  );
};

const footerStyles = {
  width: "100%",
  backgroundColor: "#B5c0af",
  padding: "20px 0",
  textAlign: "center",
  color: "#F7F5F3",
  position: "relative",
  bottom: 0,
  left: 0,
  borderTop: "1px solid #D1AE9F"
};

export default Footer;
